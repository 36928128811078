import React, { useState } from 'react'
import { Outlet } from 'react-router-dom';
import Header from './app-layouts/header';
import Sidebar from './app-layouts/sidebar';

function AppLayout() {

    const [activeSubMenu, setActiveSubMenu] = useState(null);

    return (
        <div className="main-wrapper" id='mobilemenu'>
            <Header setActiveSubMenu={setActiveSubMenu} />
            <Sidebar activeSubMenu={activeSubMenu} setActiveSubMenu={setActiveSubMenu} />
            <div className='page-wrapper'>
                <div className="content container-fluid">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}
export default AppLayout;