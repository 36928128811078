import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

function AuthLayout() {
    const auth = localStorage.getItem("token") ? true : false;
    return (
        auth ? <Navigate to="/dashboard" /> : <div className="account-page">
            <Outlet />
        </div>
    )
}
export default AuthLayout;